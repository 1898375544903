.paymentContainer {
  display: grid;
  place-items: center;
  background-color: rgb(255, 255, 255);
  height: 65vh;
  margin: 2vmax;
}

.paymentForm {
  width: 22%;
  height: 100%;
}

.paymentForm>p {
  font: 400 2vmax Arial;
  color: rgba(0, 0, 0, 0.753);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  padding: 1vmax 0;
  text-align: center;
  width: 50%;
  margin: auto;
}

.paymentForm>div {
  display: flex;
  align-items: center;
  margin: 2vmax 0;
}

.paymentInput {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  outline: none;
}

.paymentForm>div>svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.paymentFormBtn {
  border: none;
  background-color: tomato;
  color: white;
  font: 300 0.9vmax Arial;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
}

.paymentFormBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (min-width: 769px) {
  .payment-page {
    margin: 50px 100px !important;
  }

  .row.navi_ordr {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 499px) {
  .payment-page {
    margin: 50px 80px;
  }
}

@media screen and (max-width: 498px) {
  .payment-page {
    margin: 30px 20px;
  }
}

@media screen and (max-width: 600px) {
  .paymentForm {
    width: 90%;
  }

  .paymentForm>p {
    font: 400 8vw Arial;
    padding: 4vw 0;
    width: 60%;
  }

  .paymentForm>div {
    margin: 10vw 0;
  }

  .paymentInput {
    padding: 4vw 10vw;
  }

  .paymentForm>div>svg {
    font-size: 6vw;
  }

  .paymentFormBtn {
    font: 300 4vw Arial;
    padding: 4vw;
  }
}

.sabbox {
  display: flex;
  justify-content: space-between;
}

.shpngbox {
  display: flex;
  justify-content: space-between;
}

.totlbox {
  display: flex;
  justify-content: space-between;
}

p.sabret {
  color: #878787;
}

p.nxstp {
  color: #878787;
}

p.totlret {
  color: #878787;
}

a.rtcbtn {
  /* width: 100%; */
  margin-bottom: 21px;
}

.rcbtnbox.mt-2 {
  display: inline-grid;
}

.cgbox01 {
  display: flex;
  justify-content: space-between;
}

.rcbtnbox.mt-2 button.btn {
  background: #66ccff;
  padding: 10px;
  color: #fff;
  font-size: 15px;
}

p.txtsmll {
  width: 30%;
}

.mb {
  margin-bottom: 5%;
}

.m-0 {
  margin: 0px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.c-btn {
  color: #0d4bcd;
  border: none;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  padding: 0;
}

.bag-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


@media screen and (max-width: 769px) {
  .parent-div {
    margin: 4% 2% !important;
    padding: 18px !important;
  }

  .heading-1>p {
    font-size: 16px !important;
  }

  .left-panel,
  .right-panel {
    width: 100% !important;
    padding: 12px 4px !important;
    gap: 8px;
  }

  .parent-2 {
    flex-direction: column !important;
    gap: 24px !important;
  }

  .cart-item-parent {
    gap: 8px !important;
    flex-direction: column !important;
  }

  .img-f {
    width: 100% !important;
    object-fit: contain !important;
  }

  .cart-size-qty {
    text-align: left !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 4px !important;
  }
}

@media screen and (max-width: 1024px) {
  .parent-div {
    margin: 4% 2% !important;
    padding: 18px !important;
  }

  .heading-1>p {
    font-size: 16px !important;
  }

  .left-panel,
  .right-panel {
    width: 100% !important;
    padding: 12px 4px !important;
    gap: 8px;
  }

  .parent-2 {
    flex-direction: column !important;
    gap: 24px !important;
  }

  .cart-item-parent {
    gap: 8px !important;
    flex-direction: column !important;
  }

  .img-f {
    width: 100% !important;
    object-fit: contain !important;
  }

  .cart-size-qty {
    text-align: left !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 4px !important;
  }
}

@media screen and (max-width: 498px) {
  .payment-page {
    margin: 30px 20px;
  }
}

@media screen and (max-width: 600px) {
  .parent-div {
    margin: 4% 2% !important;
    padding: 18px !important;
  }

  .heading-1>p {
    font-size: 16px !important;
  }

  .left-panel,
  .right-panel {
    width: 100% !important;
    padding: 12px 4px !important;
    gap: 8px;
  }

  .parent-2 {
    flex-direction: column !important;
    gap: 24px !important;
  }

  .cart-item-parent {
    gap: 8px !important;
    flex-direction: column !important;
  }

  .img-f {
    width: 100% !important;
    object-fit: contain !important;
  }

  .cart-size-qty {
    text-align: left !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 4px !important;
  }
}