.sidenav {
  padding-top: 50px;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  background-color: var(--blue);
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 60px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  box-shadow: -5px 0px 5px rgba(57, 63, 72, 0.3);

  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    color: #fff;
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    &:hover {
        color: #f1f1f1;
    }
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .collapsible-content {
    padding: 0 18px;
    overflow: hidden;
    display: none;
    background-color: #252323;
    color: white;
    max-height: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    li {
        list-style: none;
    }
  }
}
