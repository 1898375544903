/* font face */

@font-face {
  font-family: "title";
  /* src: url(../fonts/aharoni/ahronbd.ttf); */
}

@font-face {
  font-family: "body";
  /* src: url(../fonts/calibri/Calibri-Regular.ttf); */
}
