@import './mixins';

.breadcrumbs-wrapper {
  width: 90%;
  margin: 20px auto 20px auto;
}

.breadcrumbs {
  list-style: none;
  padding: 0;
  min-height: 20px;
  margin: 0 0 5px;

  .breadcrumbs-bg {
    float: left;
    background: var(--blue);
    padding: 5px 20px;
    border-radius: 20px;
  }
  
  li {
    margin: 0;
    padding: 0;
    float: left;
    // font-family: Helvetica Neue, sans-serif;
    font-size: 14px;
    // text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 20px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    text-transform: capitalize;

    @include breakpoint(medium) {
      text-overflow: unset;
      max-width: unset;
    }
  ;

    &:first-child {
      font-size: 19px;
    }

    a {
      display: block;
      padding: 0 40px 0 0;
      color: #000000;
      // text-transform: uppercase;
      text-decoration: none;
      height: 20px;
      position: relative;
      perspective: 700px;

      &:after {
        content: "";
        width: 20px;
        height: 20px;
        border-color: #000000;
        border-style: solid;
        border-width: 1px 1px 0 0;
        -webkit-backface-visibility: hidden;
        outline: 1px solid transparent;
        position: absolute;
        right: 16px;
        transition: all 0.15s ease;
        transform: rotateZ(45deg) skew(10deg, 10deg);
      }

      &:hover:after {
        right: 15px;
        transform: rotateZ(45deg) skew(-10deg, -10deg);
      }
    }
  }
}
@media only screen and (max-width: 374px) {
  .breadcrumbs li a {
    padding: 0 20px 0 0;
}
.breadcrumbs li {
  max-width: 120px !important;
}
.breadcrumbs li a:after {
  right: 8px;
}
}

@media only screen and (max-width: 424px) {
  .breadcrumbs li {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0em;
    max-width: 130px;
}
}
