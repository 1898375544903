@import '../../assets/css/style/common/mixins';

.cart {
  .main-content-mobile {
    padding: 0.5em;
    h1 {
      display: none;
    }
  }

  button {
    font-size: 12px !important;
  }

  .info a {
    margin: 0 !important;

    &.cart-image {
      width: 30%;
      height: 30%;
    }
  }

  .heading {
    width: 100%;
    background: var(--blue);
    color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    h3 {
      display: none;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 12px;
    }
    span {
      font-size: 13px;
    }
  }

  .item-details {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey);
    background: var(--light);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    h5 {
      margin: 0.5em;
      font-size: 15px;
      text-align: left;
      width: 100%;
    }
    .product {
      display: flex;
      justify-content: start;
      width: calc(100% - 0.5em);
      align-items: center;
      border-top: 1px solid var(--grey);
      padding: 0.25em;
      position: relative;
      background: white;

      p {
        margin: 0 0 1em 0;
      }

      .cart-details {
        margin: 0.5em 0 1em 0;

        h4 {
          text-transform: uppercase;
          margin: 0 0 1em 0;
          padding: 0;
        }

        .category {
          text-transform: uppercase;
          display: inline;
        }
      }
      .info {
        display: flex;
        gap: 20px;
        padding: 10px;

        strike {
          color: var(--grey);
        }
      }

      button {
        &.remove {
          position: absolute;
          right: 5%;
          top: 50%;
          height: 2em;
          width: 2em;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          background: #fff;
          color: var(--orange);
          border-radius: 50%;
          cursor: pointer;
        }
      }
      img {
        width: 100%;
        height: 100%;
        border: 1px solid #ccc;
        border-radius: 24px;
      }
    }
  }

  .promo-form {
    margin: 0.75em 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    input[type="text"] {
      border: 1px solid var(--grey);
      width: 100%;
      height: 1.8em;
      &::placeholder {
        padding: 0.5em;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      padding: 0.5em 2em;
      border: 0;
      background: var(--blue);
      color: white;
      font-size: 13px;
      font-family: "body";

      &:hover {
        background: var(--blue-hover);
      }

      &:focus {
        outline: none;
      }
    }
  }

  .receipt {
    margin-bottom: 1em;
    .row {
      display: flex;
      justify-content: space-between;
      border: 1px solid var(--grey);
      padding: 0.5em 1em;
      p {
        margin: 0;
        font-size: 15px;
      }
    }
  }

  .cta {
    padding: 0 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .primary-cta {
      flex: 0 0 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

@include breakpoint(medium) {
  .cart {
    overflow-x: hidden;

    .background {
      position: fixed;
      right: 0;
      z-index: 2;
      top: 0;
      bottom: 0;
      background: rgb(245, 245, 245);
      width: 37%;
    }

    .header {
      position: fixed;
      top: 1.6rem;

      .search {
        margin-right: 8em;
      }

      .header-menu {
        position: relative;
        top: 0;
        right: 3rem;
      }
    }

    .top,
    h5 {
      display: none;
    }

    footer {
      position: relative;
      z-index: 3;
    }

    .main-content-mobile {
      padding: 4em;
      display: grid;
      grid-template-columns: 100%;
      min-height: 44vh;
      margin: 0;
      gap: 3%;

      .shopping-cart {
        position: relative;
        z-index: 1;
        border: 1px solid #ccc;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        a {
          margin-top: 1em;
          margin-left: 2em;
        }
      }

      h3 {
        display: block;
        margin: 0.5em 0.5em;

        &:last-child {
          margin-right: 1em;
        }
      }
    }

    .item-details {
      background: white;
      border: none;
      display: block;

      .top-row {
        display: grid;
        color: darkgray;
        font-weight: bold;
        grid-template-columns: auto 20% 20%;
        grid-gap: 1rem;
        padding: 0.5rem 1rem;
        text-align: center;

        p {
          text-align: center;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
        }
      }

      .product {
        border-top: 0;
        font-size: 16px;
        border-bottom: 1px solid #ccc;

        &:last-child {
          border: 0;
        }

        .cart-image {
          width: 75%;
          height: 75%;
        }

        img {
          width: 100%;
          height: 100%;
          border: 1px solid #ccc;
          border-radius: 24px;
        }

        .quantity {
          justify-content: center;
          display: flex;
        }

        span {
          &:last-child {
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
          }

          button {
            font-size: 18px;
            cursor: pointer;
          }
        }

        .primary-cta {
          min-width: 110px;

          i {
            margin-right: 5px;
          }
        }

        .info {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 20% auto 20% 20%;
          padding: 0.5rem 1rem;
          margin-left: 0;
        }

        button {
          &.remove {
            display: none;
          }
        }

        .price-col {
          text-align: right;
        }

        .cart-details {
          margin-top: 0;

          h4 {
            text-transform: uppercase;
            margin: 0;
            padding: 0;
          }

          p {
            margin: 1em 0;
          }
        }
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: var(--blue);
      color: #fff;
      font-size: 13px;
    }
  }

  .order-summary {
    position: relative;
    z-index: 3;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #ccc;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .title.desk {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: var(--blue);
      color: #fff;
      font-size: 13px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .items {
      padding: 0 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .receipt {
      padding: 0 1em;

      .subtotal,
      .mobile {
        display: none;
      }

      .shipping {
        border: none;
        padding: 0;
        margin: 1em 0 auto auto;

        p {
          font-size: 16px;
        }
      }
    }

    .promo-form {
      padding: 0 1em;
      margin: 0;
      align-items: baseline;
      justify-content: space-between;
      flex-direction: column;

      button {
        margin-top: 1.5em;
        margin-bottom: 2em;
        background: var(--blue);
        border-radius: 24px;
        padding: 0.5em 2em;
        cursor: pointer;
        font-weight: 600;
        border: 0;

        &:hover {
          background: var(--blue-hover);
        }

        &:focus {
          outline: 1px solid black;
        }
      }

      input[type="text"] {
        width: 100%;
      }
    }

    .total {
      padding: 0 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .desk {
    display: block;
  }
}

@include breakpoint(large) {
  .cart {
    .main-content-mobile {
      grid-template-columns: 67% 30%;
    }
  }
}

.cart-empty-bag {
  width: 90%;
  margin: 20px auto;
}