@import '../../../../assets/css/style/common/mixins';

.header-menu {
  display: none;

  @include breakpoint('medium') {
    display: flex;
    position: static !important;
    top: 3em;
    z-index: 1000;
  }

  .cart-items {
    position: relative;

    .shopping-cart-count {
      position: absolute;
      left: 11px;
      top: -10px;
      text-align: center;
      border-radius: 7px;
      width: 18px;
      height: 18px;
      background-color: #ff6161;
      border: 1px solid #fff;
      font-weight: 400;
      color: #f0f0f0;
      line-height: 16px;
      font-size: 12px;
    }
  }

  .wishlist-items {
    position: relative;

    .wishlist-count {
      position: absolute;
      left: 11px;
      top: -10px;
      text-align: center;
      border-radius: 7px;
      width: 18px;
      height: 18px;
      background-color: var(--pink);
      border: 1px solid #fff;
      font-weight: 400;
      color: #fff;
      line-height: 16px;
      font-size: 12px;
    }
  }
}

@include breakpoint(medium) {
  .header-menu {
    display: flex;
    position: fixed;
    right: 4.5%;
    top: 4.3em;
    z-index: 1000;

    .menu-items {
      margin: 0 0.5em;
      a {
        color: var(--grey);

        &.active {
          color: var(--blue);
        }
      }
    }
    :last-child {
      a {
        color: var(--dark);
      }
    }
  }
}