.productbox {
   display: flex;
   /*   align-items: center;
    justify-content: center; */
}

.imgbox img {
    max-width: 150px;
}

.txtbox {
    padding: 20px;
}
.navi_cpnfrm input.form-control {
    padding: 8px;
    width: 73%;
}
button#button-addon2 {
    padding: 10px 10px;
    background: #66ccff;
    border: unset;
    color: #fff;
    width: 20%;
}
.input-group.navi_cpnfrm {
    display: flex;
}