@import '../../../assets/css/style/common/mixins';

.wishlist-wrapper {
  width: 90%;
  margin: 40px auto;

  .empty-list {
    padding: 0.5em 1em;
  }

  .product {
    display: grid;
    grid-template-columns: 120px auto;
    gap: 2em;
    border-bottom: 1px solid var(--grey);
    padding: 1em;
    position: relative;
    background: white;

    &:last-child {
      border: 0;
    }

    .item-details {
      margin: 0.5em 0 1em 0;

      h4 {
        text-transform: uppercase;
        margin: 0 0 1em 0;
        padding: 0;
      }
    }

    button {
      &.remove {
        position: absolute;
        right: 5%;
        top: 50%;
        height: 2em;
        width: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background: #fff;
        color: var(--orange);
        border-radius: 50%;
        cursor: pointer;
      }
    }

    img {
      width: 100px;
      height: 100px;
      border: 1px solid #ccc;
      border-radius: 24px;
    }
  }
}
