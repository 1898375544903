.banner {
    display: block;
    width: 90%;
    margin: 0 auto 2em auto;
    position: relative;

    img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        min-height: 110px;
    }
}
.slick-arrow.slick-prev, .slick-arrow.slick-next{
    box-shadow: none !important;
    margin-top:18px;
}
@media only screen and (max-width: 767px) {
    .banner img {
        min-height: 80px;
  }
  .slick-arrow.slick-prev, .slick-arrow.slick-next{
    box-shadow: none !important;
    margin-top:7px;
}
}
