.primary-cta {
  min-width: 150px;
  background-color: var(--blue);
  border: none;
  outline: none;
  border-radius: 24px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background: var(--blue-hover);
  }
}
@media only screen and (max-width: 374px) {
  .primary-cta {
    min-width: 125px;
}
}