.quantity {
  display: flex;

  input,
  button {
    box-sizing: border-box;
    margin: 0;
    outline: none;
  }

  button {
    background-color: var(--blue);
    color: #fff;
    height: 36px;
    width: 36px;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    cursor: pointer;

    &.btn-minus {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    &.btn-plus {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  .quantity-field {
    background: #eeeeee;
    border: 1px solid #eeeeee;
    position: relative;
    height: 36px;
    left: 0;
    right: 0;
    text-align: center;
    width: 36px;
    display: inline-block;
    font-size: 16px;
    margin: 0 0 5px;
    resize: vertical;
  }
}