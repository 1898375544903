@import './mixins';

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 12px rgba(0,0,0,.2);
  background: transparent;
  color: #000;
  z-index: 1;

  @include breakpoint(medium) {
    background:#fcfcfc3d;
  }
}
.slick-prev {
  left: 0;
  border-radius: 0 5px 5px 0;
}
.slick-next {
  right: 0;
  border-radius: 5px 0 0 5px;
}
.slick-prev::before  {
  content: "\f053";
  color: #000;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
}
.slick-next::before {
  content: "\f054";
  color: #000;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
}