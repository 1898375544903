.input-field {
  max-width: 280px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 15px 0;
  height: 40px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;

  i {
    text-align: center;
    line-height: 40px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1rem;
  }

  input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1rem;
    color: #333;

    &::placeholder {
      color: #aaa;
      font-weight: 500;
    }
  }
}

.radio-container {
  display: block;
  width: 95%;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 17px;
  color: #282c3f;
  position: relative;
  vertical-align: middle;

  input {
    margin: 0 16px 0 0;
    visibility: hidden;
    box-sizing: border-box;
    padding: 0;

    &:checked {
      & ~ .radioIndicator {
        &:before {
          transform: rotate(-90deg);
          border-color: var(--blue);
          visibility: visible;
          background: var(--blue);
          box-shadow: inset 0 0 0 3px #fff;
        }
      }
    }
  }

  .radioIndicator {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      width: 11px;
      height: 11px;
      border: 1px solid #c3c2c9;
      border-radius: 50%;
      visibility: visible;
      display: inline-block;
      vertical-align: middle;
      padding: 2px;
      text-align: center;
    }
  }
}

.checkbox-container {
  font-size: 14px;
  display: block;
  width: 95%;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 17px;
  color: #282c3f;
  position: relative;

  input {
    margin: 0 16px 0 0;
    visibility: hidden;
    box-sizing: border-box;
    padding: 0;

    &:checked {
      & ~ .checkmark {
        border: none;
        background: var(--blue);

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #c3c2c9;
    background: #fff;
    border-radius: 2px;

    &:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 1;
      width: 7px;
      height: 4px;
      border-color: #fff;
      border-style: none none solid solid;
      border-width: 2px;
      transition: all .3s ease-in-out;
      transform: rotate(-45deg);
    }
  }
}
