@import './common/mixins';
@import './common/fonts';
@import './common/variables';

// body {
//   font-family: "body";
// }

.container {
  width: calc(100% - 5px);
}

a {
  text-decoration: none;
  color: var(--blue);
}

.mobile {
  display: block;
}

.desk {
  display: none;
}

.fas,
.fa {
  vertical-align: middle;
}

@media only screen and (min-width: 768px) {
  .pull-up {
    bottom: -0.3em;
  }

  .mobile {
    display: none;
  }

  .desk {
    display: block;
  }
}