.box-wrapper {
  position: relative;
  z-index: 1;
  border: 1px solid #ccc;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  .box-heading {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: var(--blue);
    color: #fff;
    font-size: 13px;

    h3 {
      padding: 0.5em 1em;
      margin: 0;
    }
  }

  .box-body {
    margin: 0;
    min-height: 300px;
  }
}
