@mixin breakpoint($size) {
  @if $size == xsmall {
    @media (min-width: 320px) {
      @content;
    }
  } @else if $size == small {
    @media (min-width: 480px) {
      @content;
    }
  } @else if $size == medium {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $size == large {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $size == xlarge {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == xxlarge {
    @media (min-width: 1440px) {
      @content;
    }
  }
}
