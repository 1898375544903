@import '../../../../assets/css/style/common/mixins';

.header-logo {
  align-self: baseline;
  display: flex;
  width: 165px;

  @include breakpoint('medium') {
    margin: 0;
  }

  @include breakpoint('large') {
    width: 230px;
  }

  img {
    width: 90px;

    @include breakpoint('medium') {
      width: 115px;
    }
  }
}