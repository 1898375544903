.products-container {
  display: block;
  width: 90%;
  margin: 0 auto 2em auto;
  position: relative;

  .products {
    display: block !important;
    width: 100% !important;
  }
  .slider-container {
    img {
      max-width: 100%;
      background: #fff;
      height: 190px;
    }
  }
}

.products .product {
    display: flex !important;
    text-align: center;
    // width: 80% !important;
    justify-content:center;
  }


.slick-list {
  margin-top: 20px;
}

.slick-track h2 {
  font-size: 1em;
  background: #f7f7f7;
  margin: 0;
  padding: 12px 0px 10px;
}

.slick-prev, .slick-next {
  top: 40% !important;
}
.product.top-catg a {
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px #66ccffa6;
  width: 190px;
  height: 231px;
}

@media only screen and (min-width: 1025px) {
  .products-container .slider-container img {
    height: 190px;
  }
}

@media only screen and (max-width: 980px) {
  .products-container .slider-container img {
    height: 190px;
}
}