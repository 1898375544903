html,
body {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

:root {
  --blue: rgb(30, 87, 168);
  --pink: rgb(198, 38, 197);
  --grey: rgb(191, 191, 191);
  --light: rgb(242, 242, 242);
  --dark: rgb(6, 51, 69);
  --orange: rgb(255, 111, 59);
  --green: rgba(25,135,84, 1);
  --blue-hover: #5995fd;

  /* font family variables */
  --aharoni: "Aharoni";
  --raleway: "Raleway", sans-serif;
}
