body {
  margin: 0;
  font-family: Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: Arial, sans-serif !important;
}

.shopngpeg {
  /* width: 84%; */
  width: 90%;
  overflow: hidden;
  margin: 0 auto 50px auto;
}

.cnfrmodrpeg .row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}