.range-slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  .slider {
    position: relative;
    width: 220px;
  }

  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }

  .slider__track,
  .slider__range {
    border-radius: 10px;
    height: 10px;
  }

  .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }

  .slider__range {
    background-color: var(--blue);
    z-index: 2;
  }

  .slider__left-value,
  .slider__right-value {
    color: var(--dark);
    font-size: 14px;
    margin-top: 20px;
    font-weight: 600;
  }

  .slider__left-value {
    left: 6px;
  }

  .slider__right-value {
    right: -4px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 220px;
    outline: none;

    &--left {
      z-index: 3;
    }

    &--right {
      z-index: 4;
    }

    &::-webkit-slider-thumb {
      background-color: var(--blue-hover);
      border: none;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px #ced4da;
      cursor: pointer;
      height: 18px;
      width: 18px;
      margin-top: 10px;
      pointer-events: all;
      position: relative;
    }

    /* For Firefox browsers */
    &::-moz-range-thumb {
      background-color: #f1f5f7;
      border: none;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px #ced4da;
      cursor: pointer;
      height: 18px;
      width: 18px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }
  }
}