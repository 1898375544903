.accordion-wrapper {
  .accordion-item {
    h3.accordion-title {
      font-size: 1.75rem;
      margin: 0;

      button {
        position: relative;
        display: flex;
        align-items: center;
        background: var(--blue);
        border-radius: 1rem;
        border: none;
        font-size: 1.2rem;
        width: 100%;
        text-align: left;
        color: #ffffff;
        font-weight: bold;
        margin-top: 0.5rem;
        padding: 10px;
        cursor: pointer;

        &.active {
          background-color: var(--blue);
          color: #ffffff;
          border: var(--blue) solid 1px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:hover {
          background-color: var(--blue);
          border-color: var(--blue);
        }

        span.title-wrapper {
          display: block;
          position: relative;
          width: 100%;
          font-size: 14px;
          // text-transform: uppercase;
          line-height: 22px;
        }

        span.icon-wrapper {
          width: 10%;
          display: flex;
          justify-content: center;

          span.minus {
            content: url('../../assets/images/line.svg');
            width: 16px;
            height: 16px;
          }

          span.plus {
            content: url('../../assets/images/plus.svg');
            width: 16px;
            height: 16px;
            transform: rotate(90deg);
            transition-timing-function: ease-in;
            transition: all 1s;
          }
        }
      }
    }

    .accordion-panel {
      .panel-close {
        background: #fff;
        box-sizing: border-box;
        padding: 0.5rem 1rem 0 1rem;
        opacity:0;
        width:100%;
        height:0;
        overflow: hidden;
        transition-timing-function: ease-out;
        transition: all 1s;
      }

      .panel-open {
        overflow: hidden;
        background: #fff;
        border: 1px solid #ccc;
        border-top: 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding: 1rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
        opacity:1;
        width:100%;
        height: auto;
        transition-timing-function: ease-in;
        transition: all 1s;
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  &.vertical {
    position: relative;

    .accordion-item {
      width: 28%;

      &:first-child {
        .accordion-title button {
          border-top-left-radius: 1rem !important;
        }
      }

      &:last-child {
        .accordion-title button {
          border-bottom-left-radius: 1rem !important;
        }
      }

      .icon-wrapper {
        display: none !important;
      }

      .accordion-title button {
        margin: 0 !important;
        border-radius: 0 !important;
      }
    }

    .accordion-panel {
      position: absolute;
      top: 0;
      left: 28%;
      width: 72%;

      .panel-open {
        height: 100vh;
        border: 1px solid #ccc;
        border-top-right-radius: 1rem;
        transition: none;
      }
      .panel-close {
        transition: none;
      }
    }
  }

  &.horizontal {
    .accordion-item {
      box-sizing: border-box;
      margin: 0 0 50px;
      padding: 0;
      position: relative;
      width: 100%;

      &:first-child {
        .accordion-title button {
          border-top-left-radius: 1rem !important;
        }
      }

      &:last-child {
        .accordion-title button {
          border-top-right-radius: 1rem !important;
        }
      }
    }

    .accordion-title {
      float: left;
      height: 70px;
      line-height: 70px;
      padding: 0;
      text-align: center;

      .icon-wrapper {
        display: none !important;
      }

      button {
        margin: 0 !important;
        border-radius: 0 !important;
        border: 0 !important;
        border-right: 1px solid #ccc !important;
        padding: 10px 20px !important;
      }
    }

    .accordion-panel {
      float: right;
      margin: 37px 0 0 -100%;
      box-sizing: border-box;
      padding: 0;
      position: relative;
      width: 100%;

      .panel-open {
        height: 100vh;
        border: 1px solid #ccc;
        border-top-right-radius: 1rem;
        transition: none;
      }
      .panel-close {
        transition: none;
      }
    }
  }
}
// @media only screen and (max-width: 768px) {
//   .accordion-wrapper.vertical .accordion-panel .panel-open {
//     min-height: 42vh;
//     height: unset;
//   }
// }
