@import '../../../../assets/css/style/common/mixins';

.header-strip {
  display: none;

  @include breakpoint('medium') {
    background: var(--blue);
    color: white;
    position: initial;
    z-index: 10000;
    font-size: 15px;
    padding: 0 3em;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }

  p {
    margin: 0.25em;

    @include breakpoint('medium') {
      margin: 0.25em 2%;
    }
  }
}