@import '../../assets/css/style/common/mixins';
@import './pagination';

.product-wrapper {
  display: flex;
  min-height: 600px;
  position: relative;
  width: 90%;
  overflow: hidden;
  margin: 30px auto;

  .product-filter {
    background: #fff;
    height: auto;
    left: 0;
    overflow: hidden;
    padding-right: 10px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: transform .5s ease;
    z-index: 999;

    &.visible {
      transform: translateX(0);
      transition: transform .5s ease;
      overflow-y: auto;
      height: 90%;

      .close-btn-wrapper {
        display: block;
        width: 100%;
        text-align: right;
        margin-bottom: 30px;
        border: 0;
        outline: 0;
        background: #fff;

        @include breakpoint(medium) {
          display: none;
        }

        .close-btn {
          border: 0;
          background: #fff;
        }
      }
    }

    .close-btn-wrapper {
      display: none;
    }

    @include breakpoint(medium) {
      flex: 1 1 0;
      min-height: 600px;
      margin: 0 20px 20px 0;
      position: relative;
      min-width: 220px;
      max-width: 220px;
      border-right: 1px solid #ccc;
      transform: translateX(0);
      transition: none;
      z-index: 1;
    }

    @include breakpoint(large) {
      min-width: 250px;
      max-width: 250px;
    }

    .product-filter-head {
      margin-bottom: 20px;

      .filter-label {
        display: inline-block;
        margin: 0;
      }

      .filter-clear {
        cursor: pointer;
        color: var(--orange);
        display: inline-block;
        float: right;
        margin: 0;
      }
    }

    .filter__option {
      margin-bottom: 7px;
    }


  }

  .product-results {
    flex: 1 1 0;
    margin: 0;
    padding: 0;
    position: relative;

    .rating.row {
      h5 {
        display: inline-block;
        margin: 0 0 0 10px;
        padding: 0;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 10px;
    }

    .product-results-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      align-content: stretch;
      width: 100%;
      text-align: center;
      justify-content: center;

      @include breakpoint(medium) {
        justify-content: left;
      }

      .product {
        margin: 0 15px 15px 0;
        border: 1px solid #ccc;
        border-radius: 12px;
        position: relative;
        padding: 10px 0;
        width: 124px;

        @include breakpoint(medium) {
          width: 180px;
        }

        .heart {
          background: #fff;
          border: 0;
          border-radius: 100%;
          box-shadow: 0 0 4px rgba(0,0,0,.15);
          color: #ccc;
          cursor: pointer;
          display: flex;
          inset-inline-end: 16px;
          padding: 0;
          position: absolute;
          top: 12px;
          right: 12px;
          width: 40px;
          height: 40px;
          z-index: 99;

          &:hover {
            box-shadow: 0 0 4px rgba(0,0,0,.5);
          }

          i {
            margin: auto;
          }

          &.active {
            color: var(--pink);
          }
        }

        img {
          height: 114px;

          @include breakpoint(medium) {
            height: 170px;
          }
        }
      }
    }
  }
}

.mobile-filter-btn {
  background: linear-gradient(-180deg,#f7f7f7,#fff);
  border-radius: 0;
  bottom: 49px;
  box-shadow: 0 4px 8px 0 rgba(179,179,179,.28);
  display: flex;
  height: 60px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 101;

  @include breakpoint(medium) {
    display: none;
  }

  .mobile-filter-btn-item {
    align-content: center;
    align-items: center;
    border-right: 0.1px solid #d8d8d8;
    cursor: pointer;
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
    padding: 5px;

    img {
      height: 30px;
      padding: 5px;
    }
  }
}

.btn-price-filter {
  margin-top: 25px;
}