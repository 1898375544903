.deal-container {
  display: block;
  width: 90%;
  margin: 0 auto 2em auto;
  position: relative;

  .products {
    display: block !important;
    width: 100% !important;
  }

  .slider-container {
    img {
      background: #fff;
      width: 100%;
      max-width: 100%;
    }
  }
}


.offer-banner {
  display: block;
  width: 90%;
  margin: auto;
  position: relative;

  img {
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    min-height: 110px;
  }
}