.dashboard-wrapper {
  width: 90%;
  margin: 40px auto;
  min-height: 100vh;
}
@media only screen and (max-width: 768px) {
.dashboard-wrapper {
  min-height: 100vh;
}
}
table.clientdash tr td {
  border: 1px solid #000;
  border-collapse: collapse;
  min-width: 80px;
  padding: 2px 10px;
  text-align: center;
}
.navi_grt td{
  min-width: 290px !important;
}

table.clientdash {
  width: 100%;
}
figure.imgbox.mb-0 {
  margin: 3px;
}
.pdtifobx.box {
  margin-top: 20px;
}
table.clientdash.navi_grt h5 {
  margin: 8px 0px;
}
table.clientdash.navi_grt p {
  font-size: 13px;
  line-height: 18px;
}
table.clientdash td {
  font-size: 14px;
  line-height: 16px;
}